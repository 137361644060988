<template>
    <v-sheet>
    	<v-app-bar
            dark
            fixed
            width="100%"
            src="../assets/banner-test.jpg"
        >

            <v-app-bar-title>
                Centralizator intentie Agraria
            </v-app-bar-title>

            <v-spacer></v-spacer>

            

            <v-btn @click="exportCSV()" color="primary">Salveaza CSV</v-btn>
        </v-app-bar>

        <v-sheet height="64"></v-sheet>

    	<v-simple-table ref="centralizator" class="table">
    		<template v-slot:default>
	            <thead>
	                <tr class="font-weight-black" style="white-space: nowrap;">
	                    <th bgcolor="white" class="font-weight-black"></th>
	                    <th bgcolor="white" class="font-weight-black"></th>
	                    <th bgcolor="white" class="font-weight-black"></th>
	                    <th bgcolor="white" class="font-weight-black"></th>
	                    <th bgcolor="white" class="font-weight-black"></th>
	                    <th bgcolor="white" class="font-weight-black" colspan="6">Spatiu in aer liber</th>
	                    <th bgcolor="white" class="font-weight-black" colspan="2"> </th>
	                    <th bgcolor="white" class="font-weight-black"></th>
	                    <th bgcolor="white" class="font-weight-black" colspan="7">Spatiu in interior amenajat</th>
	                    <th bgcolor="white" class="font-weight-black" colspan="8">Spatiu in interior neamenajat</th>
	                    <th bgcolor="white" class="font-weight-black"></th>
	                </tr>
	            </thead>
	            <tbody>
	                <tr class="font-weight-black" style="white-space: nowrap;">
	                    <td bgcolor="white"></td>
	                    <td bgcolor="white"></td>
	                    <td bgcolor="white"></td>
	                    <td bgcolor="white"></td>
	                    <td bgcolor="white"></td>
	                    <td bgcolor="white" colspan="2">Deschidere</td>
	                    <td bgcolor="white" colspan="2">Adancime</td>
	                    <td bgcolor="white" colspan="2">Suprafata</td>
	                    <td bgcolor="white"></td>
	                    <td bgcolor="white"></td>
	                    <td bgcolor="white"> </td>
	                    <td bgcolor="white"></td>
	                    <td bgcolor="white" colspan="2">Deschidere</td>
	                    <td bgcolor="white" colspan="2">Adancime</td>
	                    <td bgcolor="white" colspan="2">Suprafata</td>
	                    <td bgcolor="white"></td>
	                    <td bgcolor="white" colspan="2">Deschidere</td>
	                    <td bgcolor="white" colspan="2">Adancime</td>
	                    <td bgcolor="white" colspan="2">Suprafata</td>
	                    <td bgcolor="white">Inaltime</td>
	                    <td bgcolor="white">Observatii</td>
	                </tr>
	                <tr class="font-weight-black" style="white-space: nowrap;">
	                    <td bgcolor="white">Companie</td>
	                    <td bgcolor="white">Persoana de contact</td>
	                    <td bgcolor="white">Mobil</td>
	                    <td bgcolor="white">E-mail</td>
	                    <td bgcolor="white">Taxa</td>
	                    <td bgcolor="white">Minim</td>
	                    <td bgcolor="white">Maxim</td>
	                    <td bgcolor="white">Minim</td>
	                    <td bgcolor="white">Maxim</td>
	                    <td bgcolor="white">Minim</td>
	                    <td bgcolor="white">Maxim</td>
	                    <td bgcolor="white">Cort pagoda</td>
	                    <td bgcolor="white">Cort clasic</td>
	                    <td bgcolor="white">Pavilion</td>
	                    <td bgcolor="white">Laturi</td>
	                    <td bgcolor="white">Minim</td>
	                    <td bgcolor="white">Maxim</td>
	                    <td bgcolor="white">Minim</td>
	                    <td bgcolor="white">Maxim</td>
	                    <td bgcolor="white">Minim</td>
	                    <td bgcolor="white">Maxim</td>
	                    <td bgcolor="white">Laturi</td>
	                    <td bgcolor="white">Minim</td>
	                    <td bgcolor="white">Maxim</td>
	                    <td bgcolor="white">Minim</td>
	                    <td bgcolor="white">Maxim</td>
	                    <td bgcolor="white">Minim</td>
	                    <td bgcolor="white">Maxim</td>
	                    <td bgcolor="white"> </td>
	                    <td bgcolor="white"> </td>
	                </tr>
	                <tr v-for="row in centralizatorIntentieAgraria" style="white-space: nowrap;">
	                    <td class="font-weight-black">{{row[0]}}</td>
	                    <td>{{row[1]}}</td>
	                    <td>{{row[2]}}</td>
	                    <td>{{row[3]}}</td>
	                    <td>{{row[4]}}</td>
	                    <td>{{row[5]}}</td>
	                    <td>{{row[6]}}</td>
	                    <td>{{row[7]}}</td>
	                    <td>{{row[8]}}</td>
	                    <td>{{row[9]}}</td>
	                    <td>{{row[10]}}</td>
	                    <td>{{row[11]}}</td>
	                    <td>{{row[12]}}</td>
	                    <td align="right">
	                    	<span v-if="row[13]">
		                    	<v-chip v-if="row[13].includes('pavilion-agricultura')" color="green lighten-4">Agricultura</v-chip>
		                    	<v-chip v-if="row[13].includes('pavilion-zootehnie')" color="orange lighten-4">Zootehnie</v-chip>
		                    </span>
	                    </td>
	                    <td>
	                    	<span v-if="row[14]">
	                    		{{row[14].replace("stand-cu-doua-laturi-libere-spatiu-amenajat", "2-3 laturi").replace("stand-cu-o-latura-libera-spatiu-amenajat", "1 latura")}}
	                    	</span>
	                    	
	                    </td>
	                    <td>{{row[15]}}</td>
	                    <td>{{row[16]}}</td>
	                    <td>{{row[17]}}</td>
	                    <td>{{row[18]}}</td>
	                    <td>{{row[19]}}</td>
	                    <td>{{row[20]}}</td>
	                    <td>
	                    	<span v-if="row[21]">
	                    		{{row[21].replace("stand-cu-doua-laturi-libere-spatiu-neamenajat", "2-3 laturi").replace("stand-cu-o-latura-libera-spatiu-neamenajat", "1 latura")}}
	                    	</span>
	                    </td>
	                    <td>{{row[22]}}</td>
	                    <td>{{row[23]}}</td>
	                    <td>{{row[24]}}</td>
	                    <td>{{row[25]}}</td>
	                    <td>{{row[26]}}</td>
	                    <td>{{row[27]}}</td>
	                    <td>{{row[28]}}</td>
	                    <td>{{row[29]}}</td>
	                </tr>
	            </tbody>
            </template>
    	</v-simple-table>
		
    </v-sheet>
</template>
<script>
import api from '../mixins/api'

export default {
    mixins: [ api ],
    components: {
    },
    data: () => ({
    }),
    metaInfo () {
        return {
            title: 'Date Agraria',
            table: null
        }
    },
    created () {
        this.getCentralizatorAgraria();
    },
    mounted () {
    	this.table = this.$refs.centralizator.$el.querySelector('table');
    
    },
    methods: {
    	exportCSV () {
    		const csvData = this.tableToCSV(this.table);
    		const filename = `date-agraria--${this.getDate()}`;
			this.downloadCSV(csvData, filename);
    	},
    	getDate () {
    		const dataCurenta = new Date();
			const zi          = String(dataCurenta.getDate()).padStart(2, '0');
			const luna        = String(dataCurenta.getMonth() + 1).padStart(2, '0');
			const an          = dataCurenta.getFullYear();

			return zi + '-' + luna + '-' + an;
    	},
		tableToCSV (table) {
		    let csv = [];
		    const rows = table.querySelectorAll("tr");
		    
		    for (const row of rows) {
		        const rowData = [];
		        const cells = row.querySelectorAll("td, th");
		        
		        for (const cell of cells) {
		            rowData.push(cell.textContent.trim());
		        }
		        
		        csv.push(rowData.join(";"));
		    }
		    
		    return csv.join("\n");
		},
		downloadCSV (csvData, filename) {
		    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
		    const link = document.createElement("a");
		    link.href = URL.createObjectURL(blob);
		    link.download = filename;
		    link.style.display = "none";
		    document.body.appendChild(link);
		    link.click();
		    document.body.removeChild(link);
		}
	}
}
</script>
<style scoped>

	html,
	.body {
		overflow: hidden !important;
		height: 100vh !important;
	}

	.table .v-data-table__wrapper {
		max-height: calc(100vh - 64px);
		overflow-y: scroll;
	}
</style>