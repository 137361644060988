<template>

	<v-sheet
		color="#008949"
		style="min-height: 100vh;"
		class="d-flex justify-center align-center"
	>
		<div v-if="lang === 'ro'" style="color: white; text-align: center;">
			<img src="../assets/agraria.png" contain width="320" class="rounded pt-12" />
			<h5 class="title">Intentia de participare a fost inregistrata.</h5>
			<h3 class="headline">Multumim!</h3>

			<v-container>
		        <v-row>
		            <v-col cols="12" sm="12">
						<v-sheet rounded class="pa-4">
							Pe baza solicitărilor menţionate in Intentia de Participare, DLG transmite propunerea de amplasare a spaţiului expoziţional, după criterii organizatorice/logistice, în zona în care se află standurile cu adâncimi prestabilite  şi în limita disponibilului. Perioada de transmitere a propunerilor de amplasare pentru Intenţiile de Participare transmise până la data limită de transmitere (<b>15.01.2024</b>) este de <b>25.01.2024 - 15.02.2024</b>.
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<div v-else style="color: white; text-align: center;">
			<img src="../assets/agraria.png" contain width="320" class="rounded pt-12" />
			<h5 class="title">Intent to participate has been registered.</h5>
			<h3 class="headline">Thank you!</h3>

			<v-container>
		        <v-row>
		            <v-col cols="12" sm="12">
						<v-sheet rounded class="pa-4">
							On the basis of the Application of Interest, DLG will submit a proposal for the location of the exhibition space, according to organisational/logistical criteria, in the area where the stands are located with pre-established depths  and subject to availability. The period for submitting location proposals for Intent to Participate submitted by the deadline for submission (<b>15.01.2024</b>) is <b>25.01.2024 - 15.02.2024</b>.
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</v-sheet>

</template>

<script>
	export default {
		data: () => ({
	        lang: null
	    }),
	    mounted () {
	    	this.lang = this.$store.getters.getLanguage
	    }
	}
</script>

<style>
	
</style>