<template>
    <div class="login-screen">
        <v-card flat>
            <v-card-text>
                <v-form
                    v-model="valid"
                    ref="form"
                    @submit.prevent="login(email, password)"
                >
                    <v-img
                        class="mb-6"
                        height="80"
                        contain
                        src="../assets/logo-dlg.svg"
                    ></v-img>
                    <v-text-field
                        v-model="email"
                        dense
                        autofocus
                        outlined
                        :rules="[rules.required]"
                        label="Email"
                        name="email"
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="show ? 'text' : 'password'"
                        name="input-10-1"
                        dense
                        outlined
                        label="Parola"
                        @click:append="show = !show"
                    ></v-text-field>
                    <div class="d-flex justify-center">
                        <v-btn
                            color="primary"
                            type="submit"
                            class="mb-3"
                            rounded
                        >
                            Autentificare
                        </v-btn>
                    </div>
                </v-form>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>

import axios from 'axios'
import api from '../mixins/api'

export default {
    name: 'LoginView',
    mixins: [ api ],
    data: () => ({
        valid: null,
        email: null,
        password: null,
        show: false,
        rules: {
            required: value => !!value || 'Obligatoriu.'
        }
    }),
    created () {
    },
    computed: {
    },
    methods: {
    }
}
</script>

<style>
    .login-screen {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F4F7F4;
    }
</style>