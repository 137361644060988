<template>
    <div class="pt-12">
        <v-app-bar
            dark
            fixed
            width="100%"
            src="../assets/banner-test.jpg"
        >

            <v-app-bar-title>
                {{i18n(form.data, 'title')}}
            </v-app-bar-title>

            <v-spacer></v-spacer>

            <v-menu location="end">
                <template v-slot:activator="{ on, attrs }" location="end">
                    <v-btn v-if="form.data.language === 'ro'" outlined v-bind="attrs" v-on="on"class="mr-2" >
                        <v-img
                            class="rounded-circle mr-4"
                            width="24"
                            :aspect-ratio="1"
                            src="../assets/romanian-flag.svg"
                        ></v-img>

                        Romana
                    </v-btn>

                    <v-btn v-else class="mr-2" outlined v-bind="attrs" v-on="on">
                        <v-img
                            class="rounded-circle mr-4"
                            width="24"
                            :aspect-ratio="1"
                            src="../assets/english-flag.svg"
                        ></v-img>

                        English
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="changeToEnglish">
                        <v-img
                            class="rounded-circle"
                            width="24"
                            :aspect-ratio="1"
                            src="../assets/romanian-flag.svg"
                        ></v-img>
                        <v-list-item-title class="ml-4">
                            Romana
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="changeToRomanian">
                        <v-img
                            class="rounded-circle"
                            width="24"
                            :aspect-ratio="1"
                            src="../assets/english-flag.svg"
                        ></v-img>
                        <v-list-item-title class="ml-4">
                            English
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn @click="submitAgriplantaForm()" color="primary">{{i18n(form.data, 'sendButtonLabel')}}</v-btn>
        </v-app-bar>

        <div v-if="form.data">
            <img v-if="form.data.language === 'ro'" src="../assets/AGRIPLANTA_Header_2024_RO.png" style="width: 100%; display: block">
            <img v-else src="../assets/AGRIPLANTA_Header_2024_EN.png" style="width: 100%; display: block">
        </div>
        
        <!-- <v-sheet color="#008949">
            <v-sheet color="#30643f">
                <v-container>
                    <v-row dense>
                        <v-col>
                            <v-sheet
                                color="#30643f"
                                class="d-flex flex-column flex-md-row justify-space-between align-center"
                                style="width: 100% !important;"
                            >
                                <div class="d-flex flex-column flex-md-row justify-space-between align-center">
                                    <img src="../assets/logo-dlg-intermarketing.svg" contain height="156" class="rounded pt-5" />
                                    
                                </div>

                                <div class="text-center text-md-left px-5">
                                    <img style="width: 100%;" src="../assets/agriplanta-logo.png" contain class="rounded pt-0" />
                                    <h1 class="display mb-2 white--text">{{i18n(form.data, 'eventTitle')}}</h1>
                                </div>

                                <div class="d-flex flex-column justify-space-between align-md-end white--text mt-2" style="height: 100%;">
                                    <h3 class="title mb-1 text-center text-md-right">{{i18n(form.data, 'eventName')}}</h3>
                                    <h3 class="title mb-1 text-center text-md-right">{{i18n(form.data, 'eventDate')}}</h3>
                                    <h3 class="title mb-0 text-center text-md-right">www.agraria-dlg.ro</h3>
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-container>

                <v-sheet color="#54C076" height="16">
                </v-sheet>
            </v-sheet> -->

            <v-sheet
                color="#30643f"
                class="d-flex flex-column flex-md-row justify-center align-center"
                style="width: 100% !important;"
            >
                <h3 class="title mb-1 mt-5 white--text text-center text-md-right">{{i18n(form.data, 'deadline')}}</h3>
            </v-sheet>

            <v-sheet color="#30643f">
                <v-container>
                    <v-row dense>
                        <v-col>

                            <TheForm :form="form" :submitFormProp="submitFormProp" :submitAgriplantaFormProp="submitAgriplantaFormProp" />

                            <v-container class="mb-0 py-0">
                                
                                <v-row class="my-0 py-0">

                                    <v-col class="py-0">
                                    </v-col>

                                    <v-col class="py-0 text-right">
                                        <v-btn @click="submitAgriplantaForm()" class="mb-12">{{i18n(form.data, 'sendButtonLabel')}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                    
                </v-container>
            </v-sheet>

            <v-sheet color="#54C076" height="16">
            </v-sheet>

            <v-parallax src="../assets/bg-agriplanta.jpg" height="auto">

                <v-container>
                    <v-row dense class=" align-center">
                        <v-col md="2">
                            <div class="d-flex flex-column flex-md-row justify-center align-center">
                                <img src="../assets/logo-dlg-intermarketing.svg" contain width="128" />
                            </div>
                        </v-col>

                        <v-col>
                            <h4 class="white--text">Contact</h4>

                            <div class="d-flex flex-column flex-md-row justify-space-between align-center">
                                <v-list three-line color="transparent">
                                    <v-list-item class="white--text">
                                        <v-list-item-content>
                                            <v-list-item-title style="white-space: nowrap;">
                                                <v-icon class="white--text">mdi-account</v-icon>
                                                <span class="pl-3 body-1">Mareș Raluca</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="white--text" style="white-space: nowrap;">
                                                <v-icon class="white--text">mdi-phone</v-icon>
                                                <span class="pl-3 body-1">+40734 584 291</span>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="white--text" style="white-space: nowrap;">
                                                <v-icon class="white--text">mdi-mail</v-icon>
                                                <span class="pl-3 body-1">r.mares@dlg.org</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-list three-line color="transparent">
                                    <v-list-item class="white--text">
                                        <v-list-item-content>
                                            <v-list-item-title style="white-space: nowrap;">
                                                <v-icon class="white--text">mdi-account</v-icon>
                                                <span class="pl-3 body-1">Adrian Câmpean</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="white--text" style="white-space: nowrap;">
                                                <v-icon class="white--text">mdi-phone</v-icon>
                                                <span class="pl-3 body-1">+40799 742 765</span>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="white--text" style="white-space: nowrap;">
                                                <v-icon class="white--text">mdi-mail</v-icon>
                                                <span class="pl-3 body-1">a.campean@dlg.org</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-list three-line color="transparent">
                                    <v-list-item class="white--text">
                                        <v-list-item-content>
                                            <v-list-item-title style="white-space: nowrap;">
                                                <v-icon class="white--text">mdi-account</v-icon>
                                                <span class="pl-3 body-1">Iosif Roxana</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="white--text" style="white-space: nowrap;">
                                                <v-icon class="white--text">mdi-phone</v-icon>
                                                <span class="pl-3 body-1">+40732 945 418</span>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="white--text" style="white-space: nowrap;">
                                                <v-icon class="white--text">mdi-mail</v-icon>
                                                <span class="pl-3 body-1">r.iosif@dlg.org</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-col>

                        <v-col align="right">
                            <img src="../assets/frunze.png" contain height="200" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-parallax>
            
        </v-sheet>
    </div>
</template>

<script>
    
import axios from 'axios';
import { required, email, regex } from 'vee-validate/dist/rules';

import agrariaForm_f4 from '../json/agriplanta-2024.json';

import FieldTypes from '../components/fields/FieldTypes.vue'
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate';
import TheForm from '@/components/TheForm.vue'

export default {
    components: {
        ValidationObserver,
        FieldTypes,
        TheForm
    },
    
    data: () => ({
        form: agrariaForm_f4,
        submitFormProp: 0,
        submitAgriplantaFormProp: 0
    }),
    metaInfo () {
        return {
            title: this.i18n(this.form.data, 'eventName') + ' | ' + this.i18n(this.form.data, 'title')
        }
    },
    created () {
        const url = 'https://dlg.dev-factory.ro/php-test/get_user.php';
        axios.post(url, { user_url: this.$route.params.slugExpozant }).then(response => {
            this.expozant = response.data;
        });

    },
    mounted () {
        extend('required', {
            ...required,
            message: 'required',
        })

        extend('email', {
            ...email,
            message: 'email',
        })

        extend('regex', {
            ...regex,
            message: 'phone',
        })

        this.elementsPDF = this.form.data.generatorPDF.elements;
    },
    methods: {
        submitAgriplantaForm () {
            this.submitAgriplantaFormProp++;
        },
        changeToRomanian () {
            this.form.data.language = "en"
            this.$store.commit( 'updateLanguage', 'en' );
        },
        changeToEnglish () {
            this.form.data.language = "ro"
            this.$store.commit( 'updateLanguage', 'ro' );
        },
        i18n (obj, key) {
            if (!obj) return;
            if (obj.i18n) {
                if (obj.i18n[this.form.data.language]) {
                    if (obj.i18n[this.form.data.language][key]) {
                        return obj.i18n[this.form.data.language][key];
                    }
                }
            }
            return obj[key];
        }
    }
}

</script>

<style>

</style>