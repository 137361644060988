<template>

	<v-sheet
		color="#008949"
		style="min-height: 100vh;"
		class="d-flex justify-center align-center"
	>
		<div v-if="lang === 'ro'" style="color: white; text-align: center;">
			<v-sheet color="white" class="pa-4 rounded mb-4">
				<img src="../assets/Logo-APRAT-2019_PNG.png" contain width="320" />
			</v-sheet>
			<h5 class="title">Intenția de participare a fost înregistrată.</h5>
			<h3 class="headline">Mulțumim!</h3>

			<v-container>
		        <v-row>
		            <v-col cols="12" sm="12">
						<v-sheet rounded class="pa-4">
							După închiderea perioadei de transmitere a intenţiilor de participare (<b>06.02.2024</b>), pe baza solicitărilor din Intentia de Participare, DLG transmite propunerea de amplasare a spaţiului expoziţional, după criterii organizatorice/logistice, în zona în care se află standurile cu adâncimi prestabilite (menţionate în planul care va fi transmis expozantului) şi în limita disponibilului, până la data de <b>01.03.2024</b>.
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<div v-else style="color: white; text-align: center;">
			<v-sheet color="white" class="pa-4 rounded mb-4">
				<img src="../assets/Logo-APRAT-2019_PNG.png" contain width="320" />
			</v-sheet>
			<h5 class="title">Intent to participate has been registered.</h5>
			<h3 class="headline">Thank you!</h3>

			<v-container>
		        <v-row>
		            <v-col cols="12" sm="12">
						<v-sheet rounded class="pa-4">
							After the closing date for the submission of Intent to Participate (<b>06.02.2024</b>), on the basis of the requests in the Intent to Participate, DLG will submit the proposal for the location of exhibition space, according to organisational/logistical criteria, in the area where the stands are located with pre-established depths (mentioned in the plan to be sent to the exhibitor) and within the limits of availability, by <b>01.03.2024</b>.
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</v-sheet>

</template>

<script>
	export default {
		data: () => ({
	        lang: null
	    }),
	    mounted () {
	    	this.lang = this.$store.getters.getLanguage
	    }
	}
</script>

<style>
	
</style>